export default class Modal {
  constructor(id) {
    this.modal = document.querySelector(`#${id}`);
    if (!this.modal) return;

    this.modalOverlay = this.modal.querySelector('.modal__overlay');
    this.closeButton = this.modal.querySelector('.modal__close');

    this.modalOverlay.addEventListener('click', (e) => {
      if (e.target === this.modalOverlay) {

        // dont allow users to close the video modal if something is currently uploading
        if (!window.state?.isUploading) {
          this.close();
        }
      }
    });

    this.closeButton?.addEventListener('click', () => this.close());

    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape') {
        this.close();
      }
    });
  }

  static init() {
    const allModals = document.querySelectorAll("[data-modal-trigger]");
    allModals?.forEach(modalTrigger => {
      const id = modalTrigger.getAttribute("data-modal-trigger");

      // If the modal instance has not been created yet, create a new one
      if (!this.instances[id] && document.querySelector(`#${id}`)) {
        this.instances[id] = new Modal(id);

        // Adding click event listener to the trigger element
        modalTrigger.addEventListener("click", (e) => {
          Logger('triggered modal')
          e.preventDefault();
          this.show(id);
        });
      }
    });
  }

  show(options = {}) {
    if (!this.modal) return;

    this.modal.classList.add('block', 'fixed', 'pointer-events-auto');

    if (Array.from(this.modal.classList).includes('mini-modal')) {
      this.modal.classList.add('right-2', 'bottom-2')
      this.modal.querySelector('.modal__container').classList.add('shadow-lg', 'border', 'border-gray-50')
    }

    this.modal.setAttribute('aria-hidden', 'false');

    // fade it in
    setTimeout(() => {
      this.modal.classList.add('opacity-100')
    }, 200)

    if (options.onShow) {
      options.onShow();
    }

    // If an onClose callback is provided in the options, register it to be used when the modal is closed.
    if (options.onClose) {
      window.Uatu.on(`modalCloseEvent-${this.modalId}`, options.onClose);
    }

    window.recordAhoy.now({ event: `show_modal - ${this.modal.id}` })
  }

  close(options = {}) {
    if (!this.modal) return;

    this.modal.classList.remove('block', 'fixed', 'opacity-100', 'pointer-events-auto', 'transition-opacity');
    this.modal.setAttribute('aria-hidden', 'true');

    if (options.onClose) {
      options.onClose();
    }

     // Emit the modalCloseEvent using Uatu when the modal is closed
    window.Uatu.emit(`modalCloseEvent-${this.modalId}`);
    window.recordAhoy.now({ event: `close_modal - ${this.modal.id}` })
  }
}

// Object to store modal instances
Modal.instances = {};

Modal.show = function(id, options) {
  // Retrieve existing instance or create new one
  this.instances[id] = this.instances[id] || new Modal(id);
  this.instances[id].show(options);
}

Modal.close = function(id, options) {
  // Only attempt to close if instance exists
  if (this.instances[id]) {
    this.instances[id].close(options);
  }
}

window.Modal = Modal
