import { createAxiosInstance } from ".";

const instance = createAxiosInstance();

const Group = {
  getAll(teamId) {
    return instance.get(`/account/teams/${teamId}/groups.json`)
      .then(response => response.data)
      .catch(error => {
        console.error(`Error getting groups for team ${teamId}:`, error);
        throw error;
      });
  },

  get(teamId, groupId) {
    return instance.get(`/account/teams/${teamId}/groups/${groupId}.json`)
      .then(response => response.data)
      .catch(error => {
        console.error(`Error getting group ${groupId} for team ${teamId}:`, error);
        throw error;
      });
  },

  create({ teamId, groupData }) {
    return instance.post(`/account/teams/${teamId}/groups.json`, groupData)
      .then(response => response.data)
      .catch(error => {
        console.error('Error creating group:', error);
        throw error;
      });
  },

  update({ teamId, groupId, groupData }) {
    return instance.patch(`/account/teams/${teamId}/groups/${groupId}.json`, groupData)
      .then(response => response.data)
      .catch(error => {
        console.error('Error updating group:', error);
        throw error;
      });
  },

  destroy({ teamId, groupId }) {
    return instance.delete(`/account/teams/${teamId}/groups/${groupId}.json`)
      .then(response => response.data)
      .catch(error => {
        console.error('Error deleting group:', error);
        throw error;
      });
  }
};

export default Group;
