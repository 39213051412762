require('../../account/swal')

import initPayment from '../../packs/payment'

const Display = {
  permissionsError: (origin = '') => {
    recordAhoy.now({ event: `permissions_error - ${origin}` })

    window.swal('Permissions Needed', `
          Speakflow requires access to your camera and microphone. Please grant permissions in your browser.
          <br />
          <br />
          If you have already granted permissions, try restarting your browser. Email team@speakflow.com for assistance.
          <br />
          <br />
          <strong>
            <a href="https://www.speakflow.com/docs/changing-your-microphone" class="primary">Read our docs</a> for guidance.
          </strong>
        `, 'error', 'Continue')
  },
  overlaySystemPreferencesError: () => {
    recordAhoy.now({ event: 'overlay_preferences_error' })

    window.swal('Permission Needed', `
          Please enable screen sharing permissions in your system.
          <br />
          <br />
          Email team@speakflow.com for assistance.
          <br />
          <br />
          <strong>
            <a href="https://www.speakflow.com/docs/overlay-w-zoom-google-meet-etc?search=overlay">Read our docs</a> for guidance.
          </strong>
        `, 'error', 'Continue')
  },
  remoteDirections: (cb) => {
    window.swal('Control this screen from another device', `
        Load the script on another device. When you Start, Stop, or change settings
        on the other device, it will also control this device.
      `, 'success', 'Ok')
    .then(() => {
      cb && cb()
    })
  },
  internetDisconnected: () => {
    recordAhoy.now({ event: 'internet_disconnected' })

    window.swal('You are offline', `
      An internet connection is required.
      Go back online to use Speakflow.
      `, 'error', 'Ok')
  },
  timeLimit: (cb) => {
    window.swal('Limited Recording', `
      Just a heads-up, this recording will wrap up in 2 minutes. Want more time? Consider upgrading for longer takes. 😊
    `, 'warning', 'Begin Recording')
    .then(() => {
      cb && cb()
    })
  },
  outOfRecordingTime: () => {
    window.swal('Recording Limit Reached', `
      You are out of recording time. Please delete some existing videos to continue recording or upgrade your account.
      `, 'error', 'Ok')
  },
  overlayExplainer: () => {
    window.swal('Overlay', `
    <img class="rounded mb-4" src="https://res.cloudinary.com/cg3-media-llc/image/upload/w_420/v1630045203/by0mnvanthv3ttzpkyad.jpg" />
    <p class="text-base my-2">
      Speakflow's <a href="https://www.speakflow.com/docs/overlay-w-zoom-google-meet-etc" target="_blank" class="underline text-base font-medium text-pink-600">Overlay feature</a> allows you to see other applications underneath your script while you're presenting. This means you can keep an eye on your video conference participants.
    </p>
    <a href="/pricing" target="_blank" class="underline text-base font-medium text-pink-600">Upgrade to Studio</a>
    `, '', 'Ok')
  },
  upgrade: (feature = 'default') => {
    const features = {
      default: null,
      store_videos: 'to Record Video',
      sync_devices: 'to Sync Devices',
      bookmark_sections: 'to Use Bookmarks',
      ai_writing_assistant: 'to Use Writing Assistant',
      overlay: 'to use Overlay'
    }

    const description = (feature) => {
      return {
        default: 'Do more with voice recognition, AI, <br /> video recording and more.',
        store_videos: 'Use Speakflow\'s teleprompter to record, share and download videos in your browser.',
        sync_devices: 'Use Speakflow\'s teleprompter to sync & control multiple devices.',
        overlay: 'Use Speakflow\'s teleprompter to see virtual meeting participants behind your script.',
      }[feature]
    }

    const getFeature = (feature) => {
      return features[feature]
    }

    const upgradeText = getFeature(feature)

    window.swal('Upgrade to Plus', `
      <div class="mb-2">
        <p class="text-gray-500 leading-snug">
          ${description(feature)}
        </p>
        <div class="flex items-center overflow-hidden rounded-xl bg-indigo-100 my-6">
          <div class="flex-1 flex items-center justify-center self-stretch text-center text-white bg-indigo-500 min-h-full w-full">
            <div class="text-white">
              <span class="text-5xl font-black">$19</span><span class="text-xl font-bold">/mo</span>
            </div>
          </div>
          <ul class="flex-1 mx-auto text-base p-6 font-medium text-gray-900">
          <li class="flex mb-2 gap-2 items-center text-left"><i class="text-indigo-500 w-6 fa-solid fa-sparkles"></i><span class="flex-1">Unlimited scripts</span></li>
          <li class="flex mb-2 gap-2 items-center text-left"><i class="text-indigo-500 w-6 fa-solid fa-laptop-mobile"></i><span class="flex-1">Device syncing</span></li>
          <li class="flex mb-2 gap-2 items-center text-left"><i class="text-indigo-500 w-6 fa-duotone fa-microphone"></i><span class="flex-1">Unlimited flow</span></li>
          <li class="flex mb-2 gap-2 items-center text-left"><i class="text-indigo-500 w-6 fa-duotone fa-video"></i><span class="flex-1">Video recording</span></li>
          <li class="flex mb-2 gap-2 items-center text-left"><i class="text-indigo-500 w-6 fa-duotone fa-plus"></i><span class="flex-1">and more!</span></li>
          </ul>
        </div>
      </div>

      <div data-plan="plus">
        <a href="#" class="btn btn-gradient p-3 px-6 checkout js-cta-btn" data-plan="plus">Upgrade ${upgradeText || ''}</a>
      </div>

      <div class="mt-2 text-xs text-gray-400">
        Try it risk-free with our <br /> 30-day money-back guarantee.
      </div>

      <hr class="mt-6" />
    `, '', null, null, 'Continue for Free', null, () => initUpgradeModal(feature))
  }
}

function initUpgradeModal(feature) {
  initPayment('monthly')
  document.querySelector('.checkout')?.addEventListener('click', () => {
    window.recordAhoy.now({ event: `upgrade_modal:${feature}` })
  })
  document.querySelector('.swal2-confirm').classList.add('hide')
}

window.Display = Display
export default Display;
