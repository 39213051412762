import Modal from 'react-modal';
import React, { useContext, useEffect, useState } from 'react';
import GroupMembers from './GroupMembers';
import { GroupsContext } from '../GroupsIndex';
import Buttons from '../buttons';

export default function EditGroup({
  isModalOpen,
  closeModal,
  updateGroupMutation,
  onClose,
  handleDelete
}) {
  const [name, setName] = useState('');
  const [selectedMembers, setSelectedMembers] = useState([])

  const { team, selectedGroup } = useContext(GroupsContext)

  useEffect(() => {
    if (selectedGroup) {
      setName(selectedGroup.name || '');
      setSelectedMembers(selectedGroup.members?.map(member => member.id) || []);
    }
  }, [selectedGroup]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await updateGroupMutation.mutateAsync({
        teamId: team.id,
        groupId: selectedGroup.id,
        groupData: {
          group: {
            name,
            member_ids: selectedMembers?.map(member => member.id),
          }
        },
      });
      onClose();
    } catch (error) {
      console.error('Error updating group:', error);
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="Edit Group"
      className="bg-white mx-auto p-8 rounded-lg border border-gray-300 focus:outline-none"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <h2 className="text-2xl font-bold mb-2">Edit Group</h2>
      <p className="text-sm text-gray-600">
        Organize your team members and share content with specific members.
      </p>

      <hr className="my-4" />

      {selectedGroup && (
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            defaultValue={selectedGroup.name}
            onChange={(event) => setName(event.target.value)}
            className="text-input"
          />

          {
            selectedMembers && selectedMembers.length > 0 && <div className="mt-4 text-sm text-gray-600 font-medium">Group members</div>
          }

          <GroupMembers
            group={selectedGroup}
            setSelectedMembers={setSelectedMembers}
          />

          <hr className="mt-6 mb-4" />

          <div className="flex justify-between items-center">
            <div>
              <button
                className={`${(selectedMembers?.length > 0 || selectedGroup.shares_count > 0) ? 'btn disabled px-4' : ''} py-2 rounded-full text-xs font-medium black hover:text-red-600 mr-2`}
                onClick={() => handleDelete(selectedGroup.id)}
              >
                Delete group
              </button>
            </div>
            <div>
              <Buttons.Modal.Secondary onClick={closeModal}>
                Cancel
              </Buttons.Modal.Secondary>

              <Buttons.Modal.Primary type="submit">
                Save
              </Buttons.Modal.Primary>
            </div>
          </div>
        </form>
      )}
    </Modal>
  )
}
