import { createAxiosInstance } from ".";

const instance = createAxiosInstance();

const Share = {
  get(shareableType, shareableId) {
    return instance.get(`/account/shares/${shareableType.toLowerCase()}s/${shareableId}`)
      .then(response => response.data)
      .catch(error => {
        console.error(`Error getting shares for ${shareableType} ${shareableId}:`, error);
        throw error;
      });
  },

  create({ shareableType, shareableId, scriptId }) {
    return instance.post('/account/shares/create', {
      shareable_type: shareableType,
      shareable_id: shareableId,
      script_id: scriptId
    })
      .then(response => response.data)
      .catch(error => {
        console.error('Error creating share:', error);
        throw error;
      });
  },
};

export default Share;
