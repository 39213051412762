import axios from "axios";

export default function uploadLarge({ setProgress }) {
  let cloud_name,
    upload_preset,
    slice_size,
    resource_type,
    x_unique_upload_id = +new Date(),
    uploadComplete,
    response,
    upload_response,
    uploadedSoFar = 0, // Initialize uploadedSoFar
    chunkUploadedSoFar = 0 // Initialize chunkUploadedSoFar

  let CancelToken = axios.CancelToken;
  let source = CancelToken.source();  // create a source for cancel token

  function cancelUpload() { // Method to cancel the upload
    source.cancel('Upload cancelled by the user');
    Uatu.emit('video_upload_cancelled');
  }

  function init() {
    cloud_name = { value: 'cg3-media-llc' }
    upload_preset = { value: 'm98k0fw3' }
    slice_size = { value: 6000000 }
    resource_type = { value: 'video' }
    upload_response = document.getElementById("upload_response");

    processFile();
  }

  async function processFile() {
    var file = window.blobsCombined;
    var size = file.size;
    var start = 0;

    setTimeout(loop, 3);

    async function loop() {
      var end = start + parseInt(slice_size.value);

      if (end > size) {
        end = size;
      }

      var s = slice(file, start, end);

      await send(s, start, end - 1, size);

      uploadComplete = uploadedSoFar >= size; // change this line

      if (uploadComplete) {
        Logger('upload complete', new Date())
        Uatu.emit('video_upload_success', response.data);
      }

      if (end < size) {
        start += parseInt(slice_size.value);
        setTimeout(loop, 3);
      }
    }
  }

  async function send(piece, start, end, size) {
    chunkUploadedSoFar = 0; // Reset for each chunk

    let formdata = new FormData();
    formdata.append("file", piece);
    formdata.append("upload_preset", upload_preset.value);
    formdata.append("cloud_name", cloud_name.value);

    const url = `https://api.cloudinary.com/v1_1/${cloud_name.value}/${resource_type.value}/upload`;

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Unique-Upload-Id': x_unique_upload_id,
        'Content-Range': `bytes ${start}-${end}/${size}`
      },
      onUploadProgress: function(progressEvent) {
        chunkUploadedSoFar = progressEvent.loaded; // Track progress of the current chunk
        let totalUploaded = uploadedSoFar + chunkUploadedSoFar;
        let progress = (totalUploaded / size) * 100;
        setProgress(progress);
      },
      cancelToken: source.token, // use the cancel token
    };

    try {
      response = await axios.post(url, formdata, config);
      uploadedSoFar += chunkUploadedSoFar; // Add the size of the chunk to uploadedSoFar after it's successfully uploaded
    } catch (error) {
      if (axios.isCancel(error)) {
        // cancelled
      } else {
        Uatu.emit('video_upload_failed', error);
      }
    }
  }

  function slice(file, start, end) {
    var slice = file.mozSlice
      ? file.mozSlice
      : file.webkitSlice
        ? file.webkitSlice
        : file.slice
          ? file.slice
          : noop;

    return slice.bind(file)(start, end);
  }

  function noop() { }

  init();

  return { cancelUpload }; // return the cancel method
}
