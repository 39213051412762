import React from 'react';

const Buttons = {
  Modal: {
    Primary: ({ id = '', onClick, disabled, children, type = 'button' }) => (
      <button
        id={id}
        type={type}
        className="btn btn-gradient btn-regular"
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    ),
    Secondary: ({ id = '', onClick, children, type = 'button' }) => (
      <button
        id={id}
        type={type}
        onClick={onClick}
        className="text-sm font-medium black px-4 py-2 rounded mr-2 border border-transparent hover:border-gray-300 rounded-full transition"
      >
        {children}
      </button>
    ),
  },
};

export default Buttons;
