import hasFeature from "../utility/hasFeature";
import isPublicUrl from "../utility/isPublicUrl";

let channel

const registerMessagesChannel = (messagesChannel) => {
  channel = messagesChannel
}

document.addEventListener('DOMContentLoaded', () => {
  const body = document.querySelector('body')
  const script = document.querySelector('#script-body')

  if (!body || !script) return;

  const { user, origin } = body && body.dataset
  const { scriptId } = script && script.dataset

  window.Remote = {
    action: (action) => {
      if (state.timer) state.timer.resetInterval() // reset timer for any user interaction

      const excludedActions = ['initiator', 'fetch-script', 'device-width', 'device-num-lines'];
      if (!excludedActions.some(term => action.includes(term)) && !window.state.initiator) {
        return;
      }

      if (!hasFeature('sync_devices') && !isPublicUrl()) return;

      if (isPublicUrl() && !excludedActions.some(term => action.includes(term))) return

      Logger(`Sending action: ${action}`)

      channel?.perform('remote_action', {
        "remote_action": action,
        "user_id": user,
        "script_id": scriptId,
        "origin": origin
      })

    }
  }
})

export default registerMessagesChannel
