document.addEventListener("DOMContentLoaded", function () {
  document.querySelectorAll('.js-header-link').forEach((link) => {
    let timeoutId;
    const subNav = link.parentElement.querySelector('.js-header-sub-nav');

    const showSubNav = (e) => {
      // Hide and lower z-index of all other subnavs

      document.querySelectorAll('.js-header-sub-nav').forEach((t) => {
        t.classList.remove('opacity-100');
        t.classList.add('pointer-events-none');
        t.style.zIndex = ""; // Reset z-index
      });

      clearTimeout(timeoutId);
      subNav.classList.add('opacity-100');
      subNav.classList.remove('pointer-events-none');
      subNav.style.zIndex = 100; // Set a high z-index for active subnav
    };

    const hideSubNav = (e) => {
      timeoutId = setTimeout(() => {
        subNav.classList.remove('opacity-100');
        subNav.classList.add('pointer-events-none');
        subNav.style.zIndex = ""; // Reset z-index
      }, 100);
    };

    link.addEventListener('mouseover', showSubNav);
    link.addEventListener('mouseleave', hideSubNav);

    subNav.addEventListener('mouseenter', () => {
      link.classList.add('text-pink-600')
      clearTimeout(timeoutId);
    });

    subNav.addEventListener('mouseleave', () => {
      link.classList.remove('text-pink-600')
      subNav.classList.remove('opacity-100');
      subNav.classList.add('pointer-events-none');
      subNav.style.zIndex = ""; // Reset z-index
    });
  });
});
