import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['nav']
  static classes = ['shown', 'hidden']
  static values = {
    hideOthersEventName: String
  }

  get isShown() {
    if (!this.hasNavTarget) { return false }
    return !this.navTarget.classList.contains('hide')
  }

  toggle(e) {
    if (this.isShown) {
      this.hide(e)
    } else {
      this.show(e)
    }
  }

  show(e) {
    if (!this.hasNavTarget) { return false }
    this.navTarget.classList.add(...this.shownClasses)
    this.navTarget.classList.remove(...this.hiddenClasses)

    const dropdownRightEdge = this.navTarget.getBoundingClientRect().right;
    const viewportWidth = window.innerWidth;
    const isOffScreen = dropdownRightEdge > viewportWidth

    if (isOffScreen) this.navTarget.classList.add('right-0')

    this.hideOthers()
  }

  hide(e) {
    if (!this.hasNavTarget) { return false }
    if (this.isHideOthersEvent(e) && e.target === this.element) {
      return
    }

    this.navTarget.classList.remove(...this.shownClasses)
    this.navTarget.classList.add(...this.hiddenClasses)
    this.navTarget.classList.remove('right-0')
  }

  isHideOthersEvent(e) {
    if (!this.hasHideOthersEventNameValue) { return false }
    return e.type === `${this.identifier}:${this.hideOthersEventNameValue}`
  }

  hideOthers(e) {
    if (!this.hasHideOthersEventNameValue) { return }
    this.dispatch(this.hideOthersEventNameValue)
  }

  initDelayedHide(e) {
    this.hideTimer = window.setTimeout(() => {
      this.hide(e)
      this.hideTimer = undefined
    }, 300)
  }

  cancelDelayedHide(e) {
    if (!this.hideTimer) { return }
    window.clearTimeout(this.hideTimer)
    this.hideTimer = undefined
  }
}
