const getDeviceList = () => {
  navigator.mediaDevices.enumerateDevices()
    .then(function (devices) {
      const deviceList = {}
      devices.forEach(function (device) {
        const { deviceId, kind, label } = device
        if (!deviceList[kind]) deviceList[kind] = [];
        deviceList[kind].push({ label, deviceId })
      });
      window.state.deviceList = deviceList
      Uatu.emit('devices_known', deviceList)
    })
    .catch(function(err) {
      console.log(err.name + ": " + err.message);
    });
}

export default getDeviceList;
