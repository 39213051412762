import getDeviceList from "./getDeviceList"

const checkMicPermission = (state) => {
  try {
    // Check if mediaDevices loaded.
    if(navigator.mediaDevices != undefined || !window.state.micAllowed) {
      //Req microphone permissions
      window.StreamManager.create({ audio: true, video: true })
        .then(function(streamInfo) {
          // Mic permissions granted, handle however you wish
          window.state.micAllowed = true // granted, denied, prompt

          getDeviceList()

          Uatu.on('devices_known', () => {
            window.StreamManager.stopStream(streamInfo.id);
          })

          setTimeout(() => {
            Uatu.emit('handle_mic_permissions')
          }, 3000) // wait for all the tracks to stop
        })
        .catch(function (err) {
          if (JSON.parse(document.querySelector('body').dataset.test)) return;

          Logger('checkMicPermission:Mic permissions denied', err)
          Uatu.emit('permissions_blocked')
          // Display.permissionsError()

          // Mic permissions denied, handle however you wish
          if (window.setMode != undefined) {
            setStore('settings.mode', 'auto')
          }
          Uatu.emit('handle_mic_permissions')
        });

    } else {
      Logger('checkMicPermission: Out of luck at this point')
      // Out of luck at this point, handle however you wish.
    }
  } catch (error) {
    // user likely isn't on HTTPS
  }
}

export default checkMicPermission
