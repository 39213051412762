import React, { useEffect, useState } from 'react';
import axios from 'axios';
import uploadLarge from './utils/uploadLarge';

const ProgressBar = ({ progress }) => {
  return (
    <div className="w-full h-2 bg-gray-200 rounded mb-1">
      <div
        className="h-full text-xs text-white bg-indigo-600 rounded"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

const VideoProgressBar = () => {
  const [modalTime, setModalTime] = useState(null);
  const [progress, setProgress] = useState(0);
  const [upload, setUpload] = useState(null);

  window.setModalTime = setModalTime

  Uatu.on('video_modal_closed', () => {
    window.setModalTime(null)
    window.blobsCombined = null
  })

  useEffect(() => {
    if (!window.blobsCombined) return;
    setUpload(uploadLarge({ setProgress }));
  }, [modalTime])

  return (
    <div>
      <ProgressBar progress={progress} upload={upload} />
      {
        progress < 100 && (
          <button
            className="text-xs font-medium p-2 text-blue-700"
            onClick={() => {
              if (window.confirm("Are you sure you want to cancel the upload?")) {
                upload?.cancelUpload();
              }
            }}
          >
            Cancel Upload
          </button>
        )
      }
    </div>
  )
};

export default VideoProgressBar;
