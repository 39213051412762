import Modal from 'react-modal';
import React, { useContext, useState } from 'react';
import GroupMembers from './GroupMembers';
import { GroupsContext } from '../GroupsIndex';
import Buttons from '../buttons';

export default function NewGroup({
  isModalOpen,
  closeModal,
  createGroupMutation,
  onClose,
}) {
  const [name, setName] = useState('');
  const [selectedMembers, setSelectedMembers] = useState([]);

  const { team } = useContext(GroupsContext);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await createGroupMutation.mutateAsync({
        teamId: team.id,
        groupData: {
          group: {
            name,
            member_ids: selectedMembers?.map((member) => member.id),
          },
        },
      });
      onClose();
    } catch (error) {
      console.error('Error creating group:', error);
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="New Group"
      className="bg-white mx-auto p-8 rounded-lg border border-gray-300 focus:outline-none"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <h2 className="text-2xl font-bold mb-2">New Group</h2>
      <p className="text-sm text-gray-600">
        Organize your team members and share content with specific members.
      </p>

      <hr className="my-4" />

      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={name}
          onChange={(event) => setName(event.target.value)}
          className="text-input"
        />

        {selectedMembers && selectedMembers.length > 0 && (
          <div className="mt-4 text-sm text-gray-600 font-medium">
            Group members
          </div>
        )}

        <GroupMembers setSelectedMembers={setSelectedMembers} />

        <hr className="mt-6 mb-4" />

        <div className="flex justify-between items-center">
          <div></div>
          <div>
            <Buttons.Modal.Secondary onClick={closeModal}>
              Cancel
            </Buttons.Modal.Secondary>

            <Buttons.Modal.Primary type="submit">Create</Buttons.Modal.Primary>
          </div>
        </div>
      </form>
    </Modal>
  );
}
