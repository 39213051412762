window.Logger = (text, key) => {
  if (window.location.href.includes('debug') || window.location.origin.includes('localhost')  || window.location.origin.includes('dev')) {
    console.log(text, key)
  }
}

if (
  (window.location.href.includes('/s/') ||
  window.location.href.includes('scripts/')) &&
  !window.location.href.match(/\/edit|\/new/gi)
) {
  require('channels/index')
  Logger('Load channels')
}

window.Uatu = {
  on: (eventNames, action, options) => {
    options = { ...options, passive: true }
    eventNames = (typeof eventNames == 'string') ? [eventNames] : eventNames;
    eventNames.forEach(eventName => {
      window.addEventListener(eventName, event => {
        // Logger(`Uatu: received event ${eventName}`, event)
        action(event.detail)
      }, options)
    })
  },
  emit: (eventName, data) => {
    // Logger(`Uatu: dispatched event ${eventName}`)
    window.dispatchEvent(new CustomEvent(eventName, { detail: data }))
  }
}

window.shuffleArray = function(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}

window.setCookie = function(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

window.getCookie = function(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

window.eraseCookie = function(name) {
  document.cookie = name+'=; Max-Age=-99999999;' + "; path=/";
}

window.getQueryParams = () => {
  let paramsObj = {};
  let params = new URLSearchParams(new URL(window.location.href).search);

  for (let [key, value] of params.entries()) {
      paramsObj[key] = value;
  }

  return paramsObj;
}

document.addEventListener('DOMContentLoaded', function () {
  (function() {
    const wordElement = document.querySelector('#word-scroll')
    if (!wordElement) return

    wordElement.innerHTML = '&nbsp;'

    const words = wordElement.dataset.words.split(',')
    let wordIndex = 0

    const interval = () => {
      if (!wordElement) return

      if (wordIndex < words.length) {
        wordElement.innerHTML = ''
        const word = words[wordIndex]
        for(let i = 0; i < word.length; i++) {
          setTimeout(() => {
            wordElement.innerText += word[i]
          }, i * 50)
        }
        wordIndex++
      } else {
        wordIndex = 0
        interval
      }
    }

    setTimeout(() => {
      if (!wordElement) return
      interval()
      setInterval(interval, 3000)
    }, 2000)
  })()

  var arrTimes = [];
  var i = 0; // start
  var timesToTest = 5;
  var tThreshold = 270; //ms
  var testImage = document.querySelector('body').dataset.image; // small image in your server
  var dummyImage = new Image();
  var isConnectedFast = false;

  testLatency(function(avg){
    isConnectedFast = (avg <= tThreshold);
    /** output */
    Logger("Time: " + (avg.toFixed(2)) + "ms - isConnectedFast? " + isConnectedFast)

    window.isConnectedFast = isConnectedFast
  });

  /** test and average time took to download image from server, called recursively timesToTest times */
  function testLatency(cb) {
    var tStart = new Date().getTime();
    if (i<timesToTest-1) {
      dummyImage.src = testImage + '?t=' + tStart;
      dummyImage.onload = function() {
        var tEnd = new Date().getTime();
        var tTimeTook = tEnd-tStart;
        arrTimes[i] = tTimeTook;
        testLatency(cb);
        i++;
      };
    } else {
      /** calculate average of array items then callback */
      var sum = arrTimes.reduce(function(a, b) { return a + b; });
      var avg = sum / arrTimes.length;
      cb(avg);
    }
  }

  const ref = window.location.href.split('?ref=')
  if (ref && ref[1]) {
    setCookie('ref', ref[1], 3650)
    Logger('ref', ref[1])
  }

  const free_code = window.location.href.split('?free_code=')
  if (free_code && free_code[1]) {
    setCookie('free_code', free_code[1], 14)
    Logger('free_code', free_code[1])
  }

  document.querySelectorAll('.js-reveal').forEach((btn) => {
    btn.addEventListener('click', (e) => {
      const targetSelector = e.currentTarget.dataset.target;
      const target = document.querySelector(targetSelector);

      target.classList.toggle('hide');

      // Attach the event listener only if the target is shown
      if (!target.classList.contains('hide')) {
        const handleClickOutside = (event) => {
          // Close the target if the click is outside of it and the button
          if (!target.contains(event.target) && event.target !== btn) {
            target.classList.add('hide');
            document.removeEventListener('click', handleClickOutside);
          }
        };

        // Ensure the event listener isn't triggered by the current click
        setTimeout(() => {
          document.addEventListener('click', handleClickOutside);
        }, 0);
      }
    });
  });

})
