import hasFeature from "../utility/hasFeature";
import offsetAmount from "../utility/offsetAmount";
import clearAfter from "./clearAfter";
import grayOutPrevious from "./grayOutPrevious";
import registerWordAsSpoken from "./registerWordAsSpoken";

var observer

const watcher = () => {
  if (!hasFeature('manual_mode')) {
    Logger('No watcher.')
    return
  }

  if (observer) {
    observer.disconnect()
  }

  let observerOptions = {
    root: null,
    rootMargin:  `0px 0px -${window.innerHeight - offsetAmount()}px 0px`,
    threshold: 0.1 // https://splunktool.com/intersectionobserver-not-working-in-safari-or-ios
  }

  observer = new IntersectionObserver(observerCallback, observerOptions);

  function observerCallback(entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting && window.state.initiator && window.store.prompter.active) {
        const diff = Date.now() - window.state.lastRecognizedTime

        // calc the difference so that there aren't
        // multple calls to registerWordAsSpoken
        // e.g. user says word, page scrolls, and that
        // scroll event triggers the call below
        if (diff < 200) return

        grayOutPrevious(entry.target)
        registerWordAsSpoken(entry.target, { to: 'bookmark' })
        clearAfter(entry.target)
      }
    });
  };

  window.state.positions.forEach((position) => {
    const w = document.querySelector(`span[data-position="${position}"]`)
    w && observer.observe(w);
  })

  window.addEventListener('script_stop', () => {
    observer.disconnect()
  })
}

export default watcher
