/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// console.log('Hello World from Webpacker')

require("@rails/activestorage").start()
import Rails from '@rails/ujs';
import "@hotwired/turbo-rails"

import "controllers"

// turn this off for now but it feels a lot faster with it on.
// TODO: Can this be turned on?
Turbo.session.drive = false

Rails.start();

window.Rails = Rails

require('../index')
require('../nav')

import '../stylesheets/modal.scss'
import '../stylesheets/application.scss'

import debounced from 'debounced'
debounced.initialize(['input', 'mouseup']) // I think there's a bug in debounced because if I don't do this, debounced:input doesn't fire

document.addEventListener("DOMContentLoaded", function () {
  if (window.location.href.includes('debug')) {
    const oldSetInterval = window.setInterval;
    window.setInterval = function (callback, delay, ...args) {
      console.log('SetInterval called with delay:', delay);
      return oldSetInterval(callback, delay, ...args);
    };

    const oldSetTimeout = window.setTimeout;
    window.setTimeout = function (callback, delay, ...args) {
      console.log('SetTimeout called with delay:', delay);
      return oldSetTimeout(callback, delay, ...args);
    };
  }


  fetch(`${window.location.origin}/ahoy/events`, {
    method: "post",
    mode: "no-cors",
    cache: "no-store",
  }).catch(()=>{
      window.possiblyBlocked = true
  });

  Uatu.on('script_ready', () => {
    if (window.possiblyBlocked) {
      document.querySelector('.js-extension-warning').classList.remove('hide')
    }
  })

  window.Honeybadger?.configure({ apiKey: 'hbp_Ludpau486qr3shPXlul65LVd9B03CQ0CkB0p' });

  window.scrollTo(0, 0)

  const mobileMenuBtn = document.querySelector('.js-mobile-menu')
  mobileMenuBtn && mobileMenuBtn.addEventListener('click', (e) => {
    if (Array.from(mobileMenuBtn.classList).includes('open')) {
      mobileMenuBtn.classList.remove('open')
    } else {
      mobileMenuBtn.classList.add('open')
    }
  })

  const dropdowns = document.querySelectorAll('.js-nav-dropdown')
  dropdowns && dropdowns.forEach((dropdown) => {
    dropdown.addEventListener('click', (e) => {
      function isSubLink(el) {
        if (el === dropdown) { return false }
        if (el.tagName === 'A') { return true }
        const nearestLink = el.closest('a')
        if (nearestLink) { return true }
        return false
      }

      if (isSubLink(e.target)) { return }

      e.stopPropagation()

      const nav = e.currentTarget.querySelector('nav')
      if (Array.from(nav.classList).includes('hide')) {
        dropdowns.forEach((dropdown) => dropdown.querySelector('nav').classList.add('hide'))
        nav.classList.add('animate__faster')
        nav.classList.add('animate__animated')
        nav.classList.add('animate__fadeIn')
        nav.classList.remove('hide')
      } else {
        nav.classList.add('hide')
      }

      nav.addEventListener('mouseleave', (e) => {
        nav.classList.add('hide')
      })

      dropdown.querySelectorAll('button').forEach(btn => btn.addEventListener('click', (e) => {
        e.preventDefault()
      }))
    })
  })

  if (!document.querySelector('main')) return
  document.querySelector('main').addEventListener('click', (e) => {
    dropdowns.forEach((dropdown) => dropdown.querySelector('nav').classList.add('hide'))
  })
})

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

require('../src/ahoy')
