import { Controller } from "@hotwired/stimulus"
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ["groupSelect"];
  choices = [];

  connect() {
    this.groupSelectTargets.forEach((selectElement) => {
      const choiceInstance = new Choices(selectElement, {
        removeItemButton: true, // Enable the remove button for each selected item
        searchEnabled: true,    // Enable searching within the dropdown
        searchPlaceholderValue: "Search groups...", // Placeholder for the search input
        placeholderValue: "Select groups...", // Placeholder for the select input
        noResultsText: "No groups found", // Text displayed when no results are found
        noChoicesText: "No groups available", // Text displayed when no choices are available
        itemSelectText: "", // Text that is read out by screen readers when an item is selectable
        maxItemCount: -1, // Maximum number of items that can be selected (-1 for no limit)
      });

      this.choices.push(choiceInstance);
    });
  }

  disconnect() {
    this.choices.forEach(choice => {
      choice.destroy();
    });
    this.choices = [];
  }
}
