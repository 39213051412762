// withQueryClientProvider.js
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const withQueryClientProvider = (WrappedComponent) => {
  return (props) => {
    return (
      <QueryClientProvider client={queryClient}>
        <WrappedComponent {...props} />
      </QueryClientProvider>
    );
  };
};

export default withQueryClientProvider;
