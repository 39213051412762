import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  enableEditing() {
    const input = this.element.querySelector('input[type="text"]')
    input.removeAttribute('readonly')
    input.classList.add('bg-gray-100', 'dark:bg-gray-700')
    input.focus()
  }

  save(e) {
    e.target.setAttribute('readonly', true)
    e.target.classList.remove('bg-gray-100', 'dark:bg-gray-700')

    // save it
    this.element.querySelector('form').requestSubmit()
  }
}
