import React, { createContext, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Group, Team } from '../api';
import withQueryClientProvider from './withQueryClientProvider';
import EditGroup from './groups/EditGroup';
import { Notyf } from 'notyf';
import NewGroup from './groups/NewGroup';

export const GroupsContext = createContext();

const GroupsIndex = ({ teamId }) => {
  const queryClient = useQueryClient();
  const { data: groups, isLoading, isError } = useQuery(['groups'], () => Group.getAll(teamId));
  const { isLoading: isLoadingTeam, error, data: team } = useQuery(['team', teamId], () => Team.get(teamId));

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // edit modal
  const [isNewGroupModalOpen, setIsNewGroupModalOpen] = useState(false);

  const deleteGroupMutation = useMutation(
    ({ teamId, groupId }) => Group.destroy({ teamId, groupId }), // Pass the object with teamId and groupId directly
    {
      onSuccess: () => {
      queryClient.invalidateQueries('groups');
    },
    onError: (error) => {
      const notyf = new Notyf({ position: { x: 'center', y: 'top' } });
      notyf.error('Error deleting group');
      console.error('Error deleting group:', error);
    },
  });

  const updateGroupMutation = useMutation(Group.update, {
    onSuccess: () => {
      queryClient.invalidateQueries('groups');
      const notyf = new Notyf({ position: { x: 'center', y: 'top' } });
      notyf.success('Group updated successfully');
    },
    onError: (error) => {
      const notyf = new Notyf({ position: { x: 'center', y: 'top' } });
      notyf.error('Error updating group');
      console.error('Error updating group:', error);
    },
  });

  const createGroupMutation = useMutation(Group.create, {
    onSuccess: () => {
      queryClient.invalidateQueries('groups');
      const notyf = new Notyf({ position: { x: 'center', y: 'top' } });
      notyf.success('Group created successfully');
    },
    onError: (error) => {
      const notyf = new Notyf({ position: { x: 'center', y: 'top' } });
      notyf.error('Error creating group');
      console.error('Error creating group:', error);
    },
  });

  const handleDelete = (groupId) => {
    deleteGroupMutation.mutate({ teamId: team.id, groupId: groupId });
  };

  const handleEdit = (groupId) => {
    const group = groups.find((g) => g.id === groupId);
    setSelectedGroup(group);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    closeModal();
    queryClient.invalidateQueries('groups');
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsNewGroupModalOpen(false);
    setSelectedGroup(null);
  };

  if (isLoading) {
    return <div>
      Loading...
    </div>;
  }

  if (isError) {
    return <div>Error fetching groups</div>;
  }

  const tableText = 'whitespace-nowrap border-b border-gray-200 dark:border-gray-700 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-white sm:pl-6 lg:pl-8'

  return (
    <GroupsContext.Provider value={{ team, selectedGroup }}>
      <div className="container mx-auto">

      <EditGroup
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        onClose={handleModalClose}
        updateGroupMutation={updateGroupMutation}
        handleDelete={handleDelete}
      />

      <NewGroup
        isModalOpen={isNewGroupModalOpen}
        closeModal={() => setIsNewGroupModalOpen(false)}
        onClose={handleModalClose}
        onCreate={() => queryClient.invalidateQueries('groups')}
        createGroupMutation={createGroupMutation}
      />

      <section className="mb-6 flex justify-between items-center">
          <h1 className="text-2xl font-bold dark:text-white">
            Groups
            <div className="text-sm text-gray-500 tracking-normal font-medium">{ groups.length } groups</div>
          </h1>
          <div>
            <button onClick={() => setIsNewGroupModalOpen(true)} className="btn btn-regular btn-gradient">Create Group</button>
          </div>
      </section>

        <hr className="dark:border-gray-700" />

      {
        groups && groups.length == 0 && (
          <div className="bg-indigo-100 py-8 mt-4 rounded-lg border border-indigo-200 text-center text-indigo-500">
            Create a group to share scripts with select team members.
          </div>
        )
      }

      {groups && groups.length > 0 && <table className="w-full border-collapse rounded-lg border overflow-hidden">
        <thead>
          <tr>
            <th className={`${tableText} text-left`}>Name</th>
            <th className={`${tableText} text-left`}>Assigned</th>
            <th className={`${tableText} text-right`}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {!isLoading && groups.map((group, index) => (
            <tr key={group.id} className={`${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'} dark:bg-transparent`}>
              <td className={`${tableText}`}>{group.name}</td>
              <td className={`${tableText}`}>
                {
                  group.members.map((member) => (
                    <div className="p-1 text-xs font-semibold rounded bg-indigo-200 inline m-1 text-indigo-600">{member.full_name || member.email}</div>
                  ))
                }
              </td>
              <td className={`${tableText} text-right`}>
                <button
                  className="btn btn-black py-1 px-4 mr-2"
                  onClick={() => handleEdit(group.id)}
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>}
    </div>
    </GroupsContext.Provider>
  );
};

export default withQueryClientProvider(GroupsIndex);
