import axios from 'axios';
import Share from './Share';
import Team from './Team';
import Group from './Group';

function getCsrfToken() {
  const csrfMetaTag = document.querySelector("meta[name='csrf-token']");
  return csrfMetaTag ? csrfMetaTag.getAttribute("content") : "";
}

function createAxiosInstance() {
  return axios.create({
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrfToken(),
      'authenticity_token': window._token
    }
  });
}

export {
  Group,
  Share,
  Team,
  createAxiosInstance,
};
