import React, { useContext, useState } from 'react';
import { Listbox } from '@headlessui/react';
import { GroupsContext } from '../GroupsIndex';

const GroupMembers = ({ setSelectedMembers }) => {
  const { team, selectedGroup: group } = useContext(GroupsContext)

  const acceptedMemberIds = group?.members?.map(m => m.id);
  const filteredAcceptedMembers = !!acceptedMemberIds ? team.accepted_members.filter(member => acceptedMemberIds.includes(member.id)) : []

  const [selected, setSelected] = useState(filteredAcceptedMembers);

  const handleSelection = (value) => {
    let selection;
    if (selected.some((user) => user.id === value.id)) {
      selection = selected.filter((user) => user.id !== value.id)
      setSelected(selection);
    } else {
      selection = [...selected, value]
      setSelected(selection);
    }

    setSelectedMembers(selection)
  };

  const handleSelected = (value) => {
    return selected.some((selectedUser) => selectedUser.id === value.id);
  };

  return (
    <div className="flex flex-col relative mt-2">
      <Listbox value={selected} onChange={handleSelection}>
        <Listbox.Button className="relative text-gray-600 bg-white p-2 rounded-lg border border-indigo-300 font-medium text-sm text-left">
          {selected.length > 0
            ? selected.map((user) => (
                <span className="bg-indigo-200 text-indigo-600 px-2 py-1 mr-1 rounded-full text-xs">
                  {user.full_name || user.email}
                </span>
              ))
            : (
                <div className="flex justify-between items-center">
                  <span>Select members</span>
                  <i className="fas fa-chevron-down"></i>
                </div>
              )}
        </Listbox.Button>
        <Listbox.Options className="absolute bg-white rounded-md shadow-md top-10 w-full overflow-scroll" style={{ maxHeight: '200%' }}>
          {team.accepted_members.map((user) => (
            <Listbox.Option
              key={user.id}
              value={user}
              disabled={false}
              className={({ active }) =>
                `${active ? 'bg-indigo-100' : 'text-gray-700'} cursor-pointer px-4 py-2 w-full`
              }
            >
              <div className="flex gap-2 items-center">
                {handleSelected(user) && (
                  <div className="mr-2 text-indigo-500">
                    <i className="fas fa-check"></i>
                  </div>
                )}
                <div>
                  <div className="font-medium truncate text-sm">{user.full_name}</div>
                  <div className="font-medium truncate text-xs text-gray-400">{user.email}</div>
                </div>
              </div>
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );

};

export default GroupMembers;
