import axios from 'axios';

const Team = {
  getAll() {
    return axios.get('/account/teams.json')
      .then(response => response.data)
      .catch(error => {
        console.error('Error getting teams:', error);
        throw error;
      });
  },

  get(teamId) {
    return axios.get(`/account/teams/${teamId}.json`)
      .then(response => response.data)
      .catch(error => {
        console.error(`Error getting team ${teamId}:`, error);
        throw error;
      });
  },
};

export default Team;
